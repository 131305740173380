.button {
  border: none;
  background-color: #2084e6;
  color: #ffffff;
  padding: 12px 20px;
  outline: none;
  cursor: pointer;
  border-radius: 4px;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  min-width: 200px; }
  .button.space {
    margin-top: 20px; }
  .button:hover {
    opacity: 0.7; }

.input-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.input-group__input {
  -webkit-box-flex: 1;
      -ms-flex: 1 1;
          flex: 1 1;
  padding: 0 15px;
  border: none;
  background: rgba(0, 0, 0, 0.1);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  min-width: 250px;
  outline: none; }

.input-group__button .button {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  min-width: unset; }

.form-group {
  margin: 0 0 20px; }

.form-group__label {
  display: block; }

.form-group__input {
  outline: none;
  display: block;
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  border: 0;
  border-radius: 4px;
  padding: 14px 20px;
  color: rgba(0, 0, 0, 0.6);
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  line-height: inherit;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin: 10px 0 0; }
  .form-group__input:focus {
    color: rgba(0, 0, 0, 0.8); }
  .form-group__input:disabled {
    cursor: not-allowed; }

.form-group__input--error {
  -webkit-box-shadow: 0 0 0 0.2rem #ffa6a6;
          box-shadow: 0 0 0 0.2rem #ffa6a6; }

.form-errors {
  margin: 0 0 25px;
  padding: 0;
  list-style: none;
  background: #ffa6a6;
  color: #9c0b0b;
  padding: 15px;
  border-radius: 4px; }

.form-errors__item {
  margin: 0 0 5px; }
  .form-errors__item:last-child {
    margin: 0; }

.form-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 -10px; }

.form-row__col {
  margin: 0 10px;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  max-width: 100%; }

.radio-button-group {
  margin: 0 0 20px; }

.radio-button-group__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }

.radio-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative; }

.radio-button label {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  margin-right: 10px;
  padding-left: 35px;
  line-height: 25px; }

.radio-button label::before {
  display: block;
  position: absolute;
  content: '';
  margin: auto;
  border: 3px solid #cccccc;
  border-radius: 100%;
  height: 18px;
  width: 18px;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear;
  left: 0;
  top: 0;
  margin: 4px; }

.radio-button__input {
  position: absolute;
  visibility: hidden; }

.radio-button__input:checked ~ label::before {
  border: 3px solid #000000; }

.radio-button__input:checked ~ label::before {
  background: #000000; }

.contact-confirmation {
  color: #155724;
  background: #c0e4c0;
  padding: 15px;
  border-radius: 4px; }

.grid::after {
  clear: both;
  content: "";
  display: block; }

.grid__row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin: 0 -15px; }
  .grid__row.align-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }

.grid__col--xs-1 {
  -webkit-box-flex: 8.33333%;
      -ms-flex: 8.33333%;
          flex: 8.33333%;
  max-width: 8.33333%;
  padding: 0 15px; }

.grid__col--xs-2 {
  -webkit-box-flex: 16.66667%;
      -ms-flex: 16.66667%;
          flex: 16.66667%;
  max-width: 16.66667%;
  padding: 0 15px; }

.grid__col--xs-3 {
  -webkit-box-flex: 25%;
      -ms-flex: 25%;
          flex: 25%;
  max-width: 25%;
  padding: 0 15px; }

.grid__col--xs-4 {
  -webkit-box-flex: 33.33333%;
      -ms-flex: 33.33333%;
          flex: 33.33333%;
  max-width: 33.33333%;
  padding: 0 15px; }

.grid__col--xs-5 {
  -webkit-box-flex: 41.66667%;
      -ms-flex: 41.66667%;
          flex: 41.66667%;
  max-width: 41.66667%;
  padding: 0 15px; }

.grid__col--xs-6 {
  -webkit-box-flex: 50%;
      -ms-flex: 50%;
          flex: 50%;
  max-width: 50%;
  padding: 0 15px; }

.grid__col--xs-7 {
  -webkit-box-flex: 58.33333%;
      -ms-flex: 58.33333%;
          flex: 58.33333%;
  max-width: 58.33333%;
  padding: 0 15px; }

.grid__col--xs-8 {
  -webkit-box-flex: 66.66667%;
      -ms-flex: 66.66667%;
          flex: 66.66667%;
  max-width: 66.66667%;
  padding: 0 15px; }

.grid__col--xs-9 {
  -webkit-box-flex: 75%;
      -ms-flex: 75%;
          flex: 75%;
  max-width: 75%;
  padding: 0 15px; }

.grid__col--xs-10 {
  -webkit-box-flex: 83.33333%;
      -ms-flex: 83.33333%;
          flex: 83.33333%;
  max-width: 83.33333%;
  padding: 0 15px; }

.grid__col--xs-11 {
  -webkit-box-flex: 91.66667%;
      -ms-flex: 91.66667%;
          flex: 91.66667%;
  max-width: 91.66667%;
  padding: 0 15px; }

.grid__col--xs-12 {
  -webkit-box-flex: 100%;
      -ms-flex: 100%;
          flex: 100%;
  max-width: 100%;
  padding: 0 15px; }

.grid__order--xs-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1; }

.grid__order--xs-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2; }

.grid__order--xs-3 {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3; }

.grid__order--xs-4 {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4; }

.grid__order--xs-5 {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5; }

.grid__order--xs-6 {
  -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6; }

.grid__order--xs-7 {
  -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
          order: 7; }

.grid__order--xs-8 {
  -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8; }

.grid__order--xs-9 {
  -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
          order: 9; }

.grid__order--xs-10 {
  -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10; }

.grid__order--xs-11 {
  -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
          order: 11; }

.grid__order--xs-12 {
  -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
          order: 12; }

@media screen and (min-width: 576px) {
  .grid__col--sm-1 {
    -webkit-box-flex: 8.33333%;
        -ms-flex: 8.33333%;
            flex: 8.33333%;
    max-width: 8.33333%;
    padding: 0 15px; } }

@media screen and (min-width: 576px) {
  .grid__col--sm-2 {
    -webkit-box-flex: 16.66667%;
        -ms-flex: 16.66667%;
            flex: 16.66667%;
    max-width: 16.66667%;
    padding: 0 15px; } }

@media screen and (min-width: 576px) {
  .grid__col--sm-3 {
    -webkit-box-flex: 25%;
        -ms-flex: 25%;
            flex: 25%;
    max-width: 25%;
    padding: 0 15px; } }

@media screen and (min-width: 576px) {
  .grid__col--sm-4 {
    -webkit-box-flex: 33.33333%;
        -ms-flex: 33.33333%;
            flex: 33.33333%;
    max-width: 33.33333%;
    padding: 0 15px; } }

@media screen and (min-width: 576px) {
  .grid__col--sm-5 {
    -webkit-box-flex: 41.66667%;
        -ms-flex: 41.66667%;
            flex: 41.66667%;
    max-width: 41.66667%;
    padding: 0 15px; } }

@media screen and (min-width: 576px) {
  .grid__col--sm-6 {
    -webkit-box-flex: 50%;
        -ms-flex: 50%;
            flex: 50%;
    max-width: 50%;
    padding: 0 15px; } }

@media screen and (min-width: 576px) {
  .grid__col--sm-7 {
    -webkit-box-flex: 58.33333%;
        -ms-flex: 58.33333%;
            flex: 58.33333%;
    max-width: 58.33333%;
    padding: 0 15px; } }

@media screen and (min-width: 576px) {
  .grid__col--sm-8 {
    -webkit-box-flex: 66.66667%;
        -ms-flex: 66.66667%;
            flex: 66.66667%;
    max-width: 66.66667%;
    padding: 0 15px; } }

@media screen and (min-width: 576px) {
  .grid__col--sm-9 {
    -webkit-box-flex: 75%;
        -ms-flex: 75%;
            flex: 75%;
    max-width: 75%;
    padding: 0 15px; } }

@media screen and (min-width: 576px) {
  .grid__col--sm-10 {
    -webkit-box-flex: 83.33333%;
        -ms-flex: 83.33333%;
            flex: 83.33333%;
    max-width: 83.33333%;
    padding: 0 15px; } }

@media screen and (min-width: 576px) {
  .grid__col--sm-11 {
    -webkit-box-flex: 91.66667%;
        -ms-flex: 91.66667%;
            flex: 91.66667%;
    max-width: 91.66667%;
    padding: 0 15px; } }

@media screen and (min-width: 576px) {
  .grid__col--sm-12 {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
    max-width: 100%;
    padding: 0 15px; } }

@media screen and (min-width: 576px) {
  .grid__push--sm-0 {
    margin-left: 0%; } }

@media screen and (min-width: 576px) {
  .grid__push--sm-1 {
    margin-left: 8.33333%; } }

@media screen and (min-width: 576px) {
  .grid__push--sm-2 {
    margin-left: 16.66667%; } }

@media screen and (min-width: 576px) {
  .grid__push--sm-3 {
    margin-left: 25%; } }

@media screen and (min-width: 576px) {
  .grid__push--sm-4 {
    margin-left: 33.33333%; } }

@media screen and (min-width: 576px) {
  .grid__push--sm-5 {
    margin-left: 41.66667%; } }

@media screen and (min-width: 576px) {
  .grid__push--sm-6 {
    margin-left: 50%; } }

@media screen and (min-width: 576px) {
  .grid__push--sm-7 {
    margin-left: 58.33333%; } }

@media screen and (min-width: 576px) {
  .grid__push--sm-8 {
    margin-left: 66.66667%; } }

@media screen and (min-width: 576px) {
  .grid__push--sm-9 {
    margin-left: 75%; } }

@media screen and (min-width: 576px) {
  .grid__push--sm-10 {
    margin-left: 83.33333%; } }

@media screen and (min-width: 576px) {
  .grid__push--sm-11 {
    margin-left: 91.66667%; } }

@media screen and (min-width: 576px) {
  .grid__push--sm-12 {
    margin-left: 100%; } }

@media screen and (min-width: 576px) {
  .grid__order--sm-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; } }

@media screen and (min-width: 576px) {
  .grid__order--sm-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; } }

@media screen and (min-width: 576px) {
  .grid__order--sm-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; } }

@media screen and (min-width: 576px) {
  .grid__order--sm-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; } }

@media screen and (min-width: 576px) {
  .grid__order--sm-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; } }

@media screen and (min-width: 576px) {
  .grid__order--sm-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; } }

@media screen and (min-width: 576px) {
  .grid__order--sm-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; } }

@media screen and (min-width: 576px) {
  .grid__order--sm-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; } }

@media screen and (min-width: 576px) {
  .grid__order--sm-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; } }

@media screen and (min-width: 576px) {
  .grid__order--sm-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; } }

@media screen and (min-width: 576px) {
  .grid__order--sm-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; } }

@media screen and (min-width: 576px) {
  .grid__order--sm-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; } }

@media screen and (min-width: 768px) {
  .grid__col--md-1 {
    -webkit-box-flex: 8.33333%;
        -ms-flex: 8.33333%;
            flex: 8.33333%;
    max-width: 8.33333%;
    padding: 0 15px; } }

@media screen and (min-width: 768px) {
  .grid__col--md-2 {
    -webkit-box-flex: 16.66667%;
        -ms-flex: 16.66667%;
            flex: 16.66667%;
    max-width: 16.66667%;
    padding: 0 15px; } }

@media screen and (min-width: 768px) {
  .grid__col--md-3 {
    -webkit-box-flex: 25%;
        -ms-flex: 25%;
            flex: 25%;
    max-width: 25%;
    padding: 0 15px; } }

@media screen and (min-width: 768px) {
  .grid__col--md-4 {
    -webkit-box-flex: 33.33333%;
        -ms-flex: 33.33333%;
            flex: 33.33333%;
    max-width: 33.33333%;
    padding: 0 15px; } }

@media screen and (min-width: 768px) {
  .grid__col--md-5 {
    -webkit-box-flex: 41.66667%;
        -ms-flex: 41.66667%;
            flex: 41.66667%;
    max-width: 41.66667%;
    padding: 0 15px; } }

@media screen and (min-width: 768px) {
  .grid__col--md-6 {
    -webkit-box-flex: 50%;
        -ms-flex: 50%;
            flex: 50%;
    max-width: 50%;
    padding: 0 15px; } }

@media screen and (min-width: 768px) {
  .grid__col--md-7 {
    -webkit-box-flex: 58.33333%;
        -ms-flex: 58.33333%;
            flex: 58.33333%;
    max-width: 58.33333%;
    padding: 0 15px; } }

@media screen and (min-width: 768px) {
  .grid__col--md-8 {
    -webkit-box-flex: 66.66667%;
        -ms-flex: 66.66667%;
            flex: 66.66667%;
    max-width: 66.66667%;
    padding: 0 15px; } }

@media screen and (min-width: 768px) {
  .grid__col--md-9 {
    -webkit-box-flex: 75%;
        -ms-flex: 75%;
            flex: 75%;
    max-width: 75%;
    padding: 0 15px; } }

@media screen and (min-width: 768px) {
  .grid__col--md-10 {
    -webkit-box-flex: 83.33333%;
        -ms-flex: 83.33333%;
            flex: 83.33333%;
    max-width: 83.33333%;
    padding: 0 15px; } }

@media screen and (min-width: 768px) {
  .grid__col--md-11 {
    -webkit-box-flex: 91.66667%;
        -ms-flex: 91.66667%;
            flex: 91.66667%;
    max-width: 91.66667%;
    padding: 0 15px; } }

@media screen and (min-width: 768px) {
  .grid__col--md-12 {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
    max-width: 100%;
    padding: 0 15px; } }

@media screen and (min-width: 992px) {
  .grid__col--lg-1 {
    -webkit-box-flex: 8.33333%;
        -ms-flex: 8.33333%;
            flex: 8.33333%;
    max-width: 8.33333%;
    padding: 0 15px; } }

@media screen and (min-width: 992px) {
  .grid__col--lg-2 {
    -webkit-box-flex: 16.66667%;
        -ms-flex: 16.66667%;
            flex: 16.66667%;
    max-width: 16.66667%;
    padding: 0 15px; } }

@media screen and (min-width: 992px) {
  .grid__col--lg-3 {
    -webkit-box-flex: 25%;
        -ms-flex: 25%;
            flex: 25%;
    max-width: 25%;
    padding: 0 15px; } }

@media screen and (min-width: 992px) {
  .grid__col--lg-4 {
    -webkit-box-flex: 33.33333%;
        -ms-flex: 33.33333%;
            flex: 33.33333%;
    max-width: 33.33333%;
    padding: 0 15px; } }

@media screen and (min-width: 992px) {
  .grid__col--lg-5 {
    -webkit-box-flex: 41.66667%;
        -ms-flex: 41.66667%;
            flex: 41.66667%;
    max-width: 41.66667%;
    padding: 0 15px; } }

@media screen and (min-width: 992px) {
  .grid__col--lg-6 {
    -webkit-box-flex: 50%;
        -ms-flex: 50%;
            flex: 50%;
    max-width: 50%;
    padding: 0 15px; } }

@media screen and (min-width: 992px) {
  .grid__col--lg-7 {
    -webkit-box-flex: 58.33333%;
        -ms-flex: 58.33333%;
            flex: 58.33333%;
    max-width: 58.33333%;
    padding: 0 15px; } }

@media screen and (min-width: 992px) {
  .grid__col--lg-8 {
    -webkit-box-flex: 66.66667%;
        -ms-flex: 66.66667%;
            flex: 66.66667%;
    max-width: 66.66667%;
    padding: 0 15px; } }

@media screen and (min-width: 992px) {
  .grid__col--lg-9 {
    -webkit-box-flex: 75%;
        -ms-flex: 75%;
            flex: 75%;
    max-width: 75%;
    padding: 0 15px; } }

@media screen and (min-width: 992px) {
  .grid__col--lg-10 {
    -webkit-box-flex: 83.33333%;
        -ms-flex: 83.33333%;
            flex: 83.33333%;
    max-width: 83.33333%;
    padding: 0 15px; } }

@media screen and (min-width: 992px) {
  .grid__col--lg-11 {
    -webkit-box-flex: 91.66667%;
        -ms-flex: 91.66667%;
            flex: 91.66667%;
    max-width: 91.66667%;
    padding: 0 15px; } }

@media screen and (min-width: 992px) {
  .grid__col--lg-12 {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
    max-width: 100%;
    padding: 0 15px; } }

@media screen and (min-width: 992px) {
  .grid__push--lg-0 {
    margin-left: 0%; } }

@media screen and (min-width: 992px) {
  .grid__push--lg-1 {
    margin-left: 8.33333%; } }

@media screen and (min-width: 992px) {
  .grid__push--lg-2 {
    margin-left: 16.66667%; } }

@media screen and (min-width: 992px) {
  .grid__push--lg-3 {
    margin-left: 25%; } }

@media screen and (min-width: 992px) {
  .grid__push--lg-4 {
    margin-left: 33.33333%; } }

@media screen and (min-width: 992px) {
  .grid__push--lg-5 {
    margin-left: 41.66667%; } }

@media screen and (min-width: 992px) {
  .grid__push--lg-6 {
    margin-left: 50%; } }

@media screen and (min-width: 992px) {
  .grid__push--lg-7 {
    margin-left: 58.33333%; } }

@media screen and (min-width: 992px) {
  .grid__push--lg-8 {
    margin-left: 66.66667%; } }

@media screen and (min-width: 992px) {
  .grid__push--lg-9 {
    margin-left: 75%; } }

@media screen and (min-width: 992px) {
  .grid__push--lg-10 {
    margin-left: 83.33333%; } }

@media screen and (min-width: 992px) {
  .grid__push--lg-11 {
    margin-left: 91.66667%; } }

@media screen and (min-width: 992px) {
  .grid__push--lg-12 {
    margin-left: 100%; } }

@media screen and (min-width: 1200px) {
  .grid__col--xl-1 {
    -webkit-box-flex: 8.33333%;
        -ms-flex: 8.33333%;
            flex: 8.33333%;
    max-width: 8.33333%;
    padding: 0 15px; } }

@media screen and (min-width: 1200px) {
  .grid__col--xl-2 {
    -webkit-box-flex: 16.66667%;
        -ms-flex: 16.66667%;
            flex: 16.66667%;
    max-width: 16.66667%;
    padding: 0 15px; } }

@media screen and (min-width: 1200px) {
  .grid__col--xl-3 {
    -webkit-box-flex: 25%;
        -ms-flex: 25%;
            flex: 25%;
    max-width: 25%;
    padding: 0 15px; } }

@media screen and (min-width: 1200px) {
  .grid__col--xl-4 {
    -webkit-box-flex: 33.33333%;
        -ms-flex: 33.33333%;
            flex: 33.33333%;
    max-width: 33.33333%;
    padding: 0 15px; } }

@media screen and (min-width: 1200px) {
  .grid__col--xl-5 {
    -webkit-box-flex: 41.66667%;
        -ms-flex: 41.66667%;
            flex: 41.66667%;
    max-width: 41.66667%;
    padding: 0 15px; } }

@media screen and (min-width: 1200px) {
  .grid__col--xl-6 {
    -webkit-box-flex: 50%;
        -ms-flex: 50%;
            flex: 50%;
    max-width: 50%;
    padding: 0 15px; } }

@media screen and (min-width: 1200px) {
  .grid__col--xl-7 {
    -webkit-box-flex: 58.33333%;
        -ms-flex: 58.33333%;
            flex: 58.33333%;
    max-width: 58.33333%;
    padding: 0 15px; } }

@media screen and (min-width: 1200px) {
  .grid__col--xl-8 {
    -webkit-box-flex: 66.66667%;
        -ms-flex: 66.66667%;
            flex: 66.66667%;
    max-width: 66.66667%;
    padding: 0 15px; } }

@media screen and (min-width: 1200px) {
  .grid__col--xl-9 {
    -webkit-box-flex: 75%;
        -ms-flex: 75%;
            flex: 75%;
    max-width: 75%;
    padding: 0 15px; } }

@media screen and (min-width: 1200px) {
  .grid__col--xl-10 {
    -webkit-box-flex: 83.33333%;
        -ms-flex: 83.33333%;
            flex: 83.33333%;
    max-width: 83.33333%;
    padding: 0 15px; } }

@media screen and (min-width: 1200px) {
  .grid__col--xl-11 {
    -webkit-box-flex: 91.66667%;
        -ms-flex: 91.66667%;
            flex: 91.66667%;
    max-width: 91.66667%;
    padding: 0 15px; } }

@media screen and (min-width: 1200px) {
  .grid__col--xl-12 {
    -webkit-box-flex: 100%;
        -ms-flex: 100%;
            flex: 100%;
    max-width: 100%;
    padding: 0 15px; } }

.container {
  max-width: 1280px;
  padding: 0 15px;
  margin: 0 auto; }

.container--large {
  max-width: 1600px;
  padding: 0 15px;
  margin: 0 auto; }

.iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; }
  .iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.nav-bar {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background-color: #151515;
  z-index: 10;
  height: 64px;
  line-height: 64px;
  padding: 0 15px;
  left: 0;
  right: 0;
  top: 0;
  -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
          box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3); }

.nav-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none; }
  @media only screen and (max-width: 767px) {
    .nav-list {
      display: none; } }

.nav-list--mobile {
  display: none; }
  @media only screen and (max-width: 767px) {
    .nav-list--mobile {
      display: block; } }

.nav-list__elem {
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  padding: 0 15px;
  line-height: 64px; }
  .nav-list__elem i {
    color: #ffffff; }
  .nav-list__elem:hover {
    background-color: grey; }
    .nav-list__elem:hover .nav-list__link:before {
      visibility: visible;
      -webkit-transform: scaleX(1);
              transform: scaleX(1); }

.nav-list__link {
  position: relative;
  color: #ffffff; }
  .nav-list__link:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: #000;
    visibility: hidden;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s; }

.mobile-nav {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  top: 0;
  left: 0;
  right: 0;
  max-height: 0;
  height: 100%;
  background-color: #ffffff;
  overflow-y: hidden; }

.mobile-nav__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 15px;
  border-bottom: 1px solid #cccccc; }
  .mobile-nav__header h4, .mobile-nav__header .h4 {
    margin: 0; }

.mobile-nav-list__elem {
  text-align: center; }

.mobile-nav-list__link {
  display: block;
  font-weight: 700;
  font-size: 24px;
  margin: 15px 0; }

.mobile-nav-list {
  margin: auto;
  list-style: none;
  padding: 0; }

.slide-down-enter {
  max-height: 0; }

.slide-down-enter-active {
  max-height: 100vh;
  -webkit-transition: max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out; }

.slide-down-enter-done {
  max-height: 100vh; }

.slide-down-exit {
  max-height: 100vh; }

.slide-down-exit-active {
  max-height: 0;
  -webkit-transition: max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out; }

.text-center {
  text-align: center; }

.text-right {
  text-align: right; }

a {
  text-decoration: none;
  color: #505050; }
  a.block {
    display: block; }

h1, .h1 {
  font-size: 2.5rem;
  line-height: normal; }

h2, .h2 {
  font-size: 2.25rem;
  line-height: normal; }

h3, .h3 {
  font-size: 2rem;
  line-height: normal; }

h4, .h4 {
  font-size: 1.75rem;
  line-height: normal; }

h5, .h5 {
  font-size: 1.5rem;
  line-height: normal; }

h6, .h6 {
  font-size: 1.25rem;
  line-height: normal; }

p,
li {
  line-height: 24px; }

.main-image {
  position: relative;
  height: 100vh; }

.main-image__caption .bottom span {
  will-change: opacity, filter;
  opacity: 0;
  -webkit-filter: blur(0px);
          filter: blur(0px);
  font-family: 'Permanent Marker', cursive; }

.main-image__caption {
  position: relative;
  text-align: center; }
  @media only screen and (min-width: 992px) {
    .main-image__caption h1, .main-image__caption .h1 {
      font-size: 48px; } }

.about-image-container {
  border: 1px solid #cccccc; }

.about-image {
  position: relative;
  bottom: -15px;
  left: -15px;
  -webkit-box-shadow: -4px 4px 15px rgba(0, 0, 0, 0.5);
          box-shadow: -4px 4px 15px rgba(0, 0, 0, 0.5); }
  @media only screen and (max-width: 1199px) {
    .about-image {
      bottom: -5px;
      left: -5px; } }

@media only screen and (max-width: 1199px) {
  .about-image-caption {
    max-height: 500px;
    overflow: auto; } }

.podcast-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.podcast-list__elem {
  padding-top: 350px;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 25%;
          flex: 1 1 25%;
  background-size: cover;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  cursor: pointer; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .podcast-list__elem {
      padding-top: 250px; } }
  @media only screen and (max-width: 991px) {
    .podcast-list__elem {
      padding-top: 200px; } }
  @media only screen and (max-width: 575px) {
    .podcast-list__elem {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 50%;
              flex: 1 1 50%; } }
  .podcast-list__elem .title-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4); }
  .podcast-list__elem .title {
    position: absolute;
    bottom: 15px;
    left: 0;
    right: 0;
    text-align: center;
    font-weight: 700;
    color: #ffffff;
    margin: 0;
    padding: 0 15px; }
  .podcast-list__elem:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    z-index: 1;
    -webkit-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3);
            box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.3); }

.youtube-feed {
  position: relative;
  margin-top: 20px; }
  .youtube-feed .slick-list {
    margin: 0 -10px; }
  .youtube-feed .slick-prev,
  .youtube-feed .slick-next {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 1;
    background: #151515;
    padding: 15px;
    cursor: pointer;
    -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
            box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3); }
    .youtube-feed .slick-prev i,
    .youtube-feed .slick-next i {
      color: #ffffff; }
  .youtube-feed .slick-prev {
    left: -30px; }
    @media only screen and (max-width: 1199px) {
      .youtube-feed .slick-prev {
        left: -10px; } }
  .youtube-feed .slick-next {
    right: -30px; }
    @media only screen and (max-width: 1199px) {
      .youtube-feed .slick-next {
        right: -10px; } }

.youtube-feed__elem .inner {
  margin: 0 10px; }

.youtube-feed__image {
  position: relative; }
  .youtube-feed__image img {
    width: 100%; }

.youtube-feed__duration {
  position: absolute;
  bottom: 15px;
  right: 15px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.8);
  color: #ffffff; }

.podcast {
  padding: 25px 0;
  border-bottom: 2px solid #cccccc; }

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  list-style: none;
  margin: 0;
  margin-top: 50px;
  padding: 0; }

.pagination__elem {
  padding: 10px 15px;
  border-right: 1px solid #cccccc;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  cursor: pointer; }
  .pagination__elem:first-child {
    border-left: 1px solid #cccccc;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }
  .pagination__elem:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px; }

.pagination__elem--active {
  background-color: #2084e6;
  color: #ffffff; }

.pagination__elem--placeholder {
  cursor: not-allowed; }

html {
  font-family: 'Sarabun', sans-serif; }

main {
  margin-top: 72px; }

.divider {
  height: 2px;
  background-color: #cccccc; }

.responsive-img {
  max-width: 100%;
  height: auto;
  display: block; }

.responsive-img--centered {
  margin: 0 auto; }

.mb-15 {
  margin-bottom: 15px; }

.loader-container {
  position: fixed;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000; }

.loader {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #2084e6;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
  margin: auto; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.flex-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.social-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0; }

.social-list__elem {
  display: block;
  padding: 0 25px;
  font-size: 24px; }

.resource-list {
  margin: 0;
  padding: 0;
  list-style: none; }

.resource-list__elem {
  margin: 5px 0; }

.sponsors-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.sponsors-grid__image {
  padding-top: 250px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }
  @media only screen and (max-width: 991px) {
    .sponsors-grid__image {
      padding-top: 150px; } }

.sponsors-grid__col {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 25%;
          flex: 1 1 25%;
  position: relative;
  padding: 0 15px; }
  @media only screen and (max-width: 575px) {
    .sponsors-grid__col {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 50%;
              flex: 1 1 50%; } }

.ig-feed {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.ig-feed__post {
  padding-top: 300px;
  display: block;
  position: relative;
  cursor: pointer;
  background-size: cover;
  -webkit-box-flex: 16.6667%;
      -ms-flex: 16.6667%;
          flex: 16.6667%;
  max-width: 16.6667%; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .ig-feed__post {
      padding-top: 250px;
      -webkit-box-flex: 33.3333%;
          -ms-flex: 33.3333%;
              flex: 33.3333%;
      max-width: 33.3333%; } }
  @media only screen and (max-width: 991px) {
    .ig-feed__post {
      padding-top: 200px;
      -webkit-box-flex: 33.3333%;
          -ms-flex: 33.3333%;
              flex: 33.3333%;
      max-width: 33.3333%; } }
  @media only screen and (max-width: 575px) {
    .ig-feed__post {
      padding-top: 200px;
      -webkit-box-flex: 50%;
          -ms-flex: 50%;
              flex: 50%;
      max-width: 50%; } }
  .ig-feed__post:hover .ig-overlay {
    opacity: 1; }

.ig-overlay {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out; }
  .ig-overlay i {
    font-size: 24px;
    margin: auto;
    color: #ffffff; }

.ReactTypeformEmbed {
  position: relative !important;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 500px !important;
  overflow: hidden;
  border: 1px solid #ececec; }

*, ::after, ::before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }
